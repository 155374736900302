import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { useCheckUnique } from '@tzmedical/react-hooks';

import FormStringInput from '../../components/common/FormStringInput.jsx';
import units from '../../units.js';

const unitOptions = units.map((unit) => ({ id: unit, name: unit }));

function ParametersInputRow({
  // Props
  control,
  list,
  index,
  handleDeleteRow,
}) {
  const theme = useTheme();

  const onDelete = React.useCallback(() => handleDeleteRow(index), [handleDeleteRow, index]);

  const checkUniqueName = useCheckUnique(
    list.toSpliced(index, 1),
    'name',
    null,
    'Name must be unique'
  );

  return (
    <TableRow
      data-cy={`parameter-row-${index}`}
      sx={{
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <TableCell>
        <FormStringInput
          control={control}
          name={`parameters.${index}.name`}
          label="Name"
          required
          rules={{
            required: 'Name is required!',
            validate: {
              unique: checkUniqueName,
            },
          }}
          data-cy="parameter-name"
        />
      </TableCell>
      <TableCell>
        <FormStringInput
          control={control}
          name={`parameters.${index}.value`}
          label="Value"
          type="number"
          required
          rules={{
            required: 'Value is required!',
          }}
          data-cy="parameter-value"
        />
      </TableCell>
      <TableCell>
        <FormStringInput
          control={control}
          name={`parameters.${index}.units`}
          label="Units"
          required
          options={unitOptions}
          rules={{
            required: 'Units is required!',
          }}
          data-cy="parameter-unit"
        />
      </TableCell>
      <TableCell align="right">
        <IconButton color="error" onClick={onDelete} data-cy={`delete-parameter-${index}`}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default ParametersInputRow;
