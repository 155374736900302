import React from 'react';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';

import ExportIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';

import { useFilter, useInterval, useLocalStorage, useSort } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import Pagination from '../../components/common/Pagination.jsx';
import EditButton from '../../components/EditButton.jsx';
import EquipmentForm from '../../components/EquipmentForm.jsx';
import ToggleButton from '../../components/ToggleButton.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import EquipmentHeader from './EquipmentHeader.jsx';
import EquipmentRow from './EquipmentRow.jsx';

const DATA_REFRESH_INTERVAL_MS = 30_000;
const searchFields = {
  serial: 'Serial',
  name: 'Name',
  location: 'Location',
  is: { enabled: (equipment) => !equipment.IsDisabled },
};
const searchHelper = [
  { label: 'Has the words', keyword: '+', variant: 'global' },
  { label: "Doesn't have", keyword: '-', variant: 'global' },
  { label: 'Serial Number', keyword: 'serial', variant: 'negatable' },
  { label: 'Name', keyword: 'name', variant: 'negatable' },
  { label: 'Location', keyword: 'location', variant: 'negatable' },
];
const sortOptions = {
  defaultSort: {
    field: 'Name',
    reverse: false,
  },
};
const pageSize = 50;

//-----------------------------------------------------------------------------
function EquipmentPage() {
  const roles = useAuth0Roles();
  const canEdit = React.useMemo(() => roles.includes('Progress-Tracker Admin'), [roles]);

  const [equipmentList, setEquipmentList] = React.useState([]);
  const [partList, setPartList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);
  const { pathname } = useLocation();
  const [showDisabled, setShowDisabled] = useLocalStorage(`showDisabled-${pathname}`, false);

  const handleDisabledToggle = React.useCallback(() => {
    setShowDisabled((value) => !value);
  }, [setShowDisabled]);

  //---------------------------------------------------------------------------
  // Navbar page buttons
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);

  const downloadCSV = React.useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: '/api/equipment/export',
      });
      const blob = new Blob([data], { type: 'text/csv' });
      const element = window.document.createElement('a');

      element.href = window.URL.createObjectURL(blob);
      element.download = 'EquipmentData.csv';

      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (err) {
      setError(err?.response?.data?.error || err.message);
    }
  }, []);

  React.useEffect(() => {
    setPageButtons(
      <>
        <ListItem sx={{ mb: 1 }}>
          <EquipmentForm setTableReload={setTableLoading} equipmentList={equipmentList} />
        </ListItem>
        {canEdit && (
          <ListItem sx={{ mb: 1 }}>
            <EditButton data-cy="edit-equipment">Edit Equipment</EditButton>
          </ListItem>
        )}
        {canEdit && (
          <ListItem sx={{ mb: 1 }}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={downloadCSV}
              startIcon={<ExportIcon />}
              data-cy="export-equipment"
            >
              Export To CSV
            </Button>
          </ListItem>
        )}
        <ListItem sx={{ mb: 1 }}>
          <ToggleButton
            data-cy="toggle-disabled"
            onClick={handleDisabledToggle}
            enabled={showDisabled}
            enabledIcon={<VisibilityIcon />}
            disabledIcon={<VisibilityOffIcon />}
            enabledMessage="Hide Disabled"
            disabledMessage="Show Disabled"
          />
        </ListItem>
      </>
    );
    return () => setPageButtons(null);
  }, [equipmentList, setPageButtons, canEdit, showDisabled, handleDisabledToggle, downloadCSV]);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getEquipmentAndParts = React.useCallback(async () => {
    try {
      if (!error) {
        const [equipmentResponse, partResponse] = await Promise.all([
          axios({
            method: 'GET',
            url: '/api/equipment',
          }),
          axios({
            method: 'GET',
            url: '/api/parts',
          }),
        ]);
        const equipmentWithComputedDates = equipmentResponse.data.map((equipment) => {
          const lastActivityByType = equipment.MaintenanceRequirements.map((requirement) => {
            const [lastActivity] = equipment.Activities.filter(
              (activity) => activity.RequirementType === requirement.RequirementType
            ).sort((a, b) => DateTime.fromISO(b.ActivityDate) - DateTime.fromISO(a.ActivityDate));

            return {
              ...requirement,
              lastActivityDate: lastActivity?.ActivityDate ?? null,
            };
          });

          const mostRecentActivity = lastActivityByType.reduce((latest, activity) => {
            if (activity.lastActivityDate && (!latest || activity.lastActivityDate > latest)) {
              return activity.lastActivityDate;
            }
            return latest;
          }, null);

          const nextActivityDates = lastActivityByType
            .map(({ lastActivityDate, IntervalPeriod, IntervalUnits }) => {
              if (IntervalUnits === 'Operating Hours' || IntervalUnits === 'Unknown') {
                return null;
              }
              const baseDate = lastActivityDate
                ? DateTime.fromISO(lastActivityDate)
                : DateTime.now();
              return baseDate.plus({ [IntervalUnits.toLowerCase()]: IntervalPeriod }).toISO();
            })
            .filter(Boolean);

          const nextActivityDate = nextActivityDates.reduce((earliest, date) => {
            return !earliest || date < earliest ? date : earliest;
          }, null);

          return {
            ...equipment,
            LastActivity: mostRecentActivity,
            NextActivity: nextActivityDate,
          };
        });

        setEquipmentList(equipmentWithComputedDates);
        setPartList(partResponse.data);
        setError(null);
        setTableLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [error]);
  useInterval(getEquipmentAndParts, DATA_REFRESH_INTERVAL_MS, tableLoading && !error);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const { search, setSearch, setSearchHelper, setSearchFields } = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearchHelper('');
      setSearchFields('');
    };
  }, [setSearchFields, setSearchHelper]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const formattedSearch = React.useMemo(() => {
    return showDisabled ? search : `${search} is:enabled`;
  }, [search, showDisabled]);
  const filteredEquipment = useFilter(equipmentList, formattedSearch, searchFields);
  const [sortedEquipment, handleSortSelection, sort] = useSort(filteredEquipment, sortOptions);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageEquipment = React.useMemo(
    () => sortedEquipment.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedEquipment]
  );
  React.useEffect(() => {
    setPage(0);
  }, [search]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  if (tableLoading) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <EquipmentHeader sort={sort} setSort={handleSortSelection} />
      {pageEquipment.map((equipment) => (
        <EquipmentRow
          key={equipment.Serial}
          equipment={equipment}
          equipmentList={equipmentList}
          partList={partList}
          setTableReload={setTableLoading}
        />
      ))}
      <Pagination
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        count={sortedEquipment.length}
      />
    </>
  );
}

export default EquipmentPage;
