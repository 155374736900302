import React from 'react';
import { useLocation } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ListItem from '@mui/material/ListItem';

import { useFilter, useInterval, useLocalStorage, useSort } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import Pagination from '../../components/common/Pagination.jsx';
import EditButton from '../../components/EditButton.jsx';
import PartsForm from '../../components/PartsForm.jsx';
import ToggleButton from '../../components/ToggleButton.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import PartsHeader from './PartsHeader.jsx';
import PartsRow from './PartsRow.jsx';

const DATA_REFRESH_INTERVAL_MS = 30_000;
const searchFields = {
  part: 'PartNumber',
  description: 'Description',
  qcp: 'QualityControlPlanId',
  vendor: 'Vendor',
  notes: 'Notes',
  gtin: 'GTIN',
  is: { enabled: (part) => !part.IsDisabled },
};
const searchHelper = [
  { label: 'Has the words', keyword: '+', variant: 'global' },
  { label: "Doesn't have", keyword: '-', variant: 'global' },
  { label: 'Part Number', keyword: 'part', variant: 'negatable' },
  { label: 'Quality Control Plan', keyword: 'qcp', variant: 'negatable' },
  { label: 'GTIN', keyword: 'gtin', variant: 'negatable' },
  { label: 'Vendor', keyword: 'vendor', variant: 'negatable' },
  { label: 'Notes', keyword: 'notes', variant: 'negatable' },
];
const sortOptions = {
  defaultSort: {
    field: 'PartNumber',
    reverse: false,
  },
};
const pageSize = 50;

function PartsPage() {
  const [partList, setPartList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);

  const roles = useAuth0Roles();
  const canEdit = React.useMemo(() => roles.includes('Progress-Tracker Admin'), [roles]);

  const { pathname } = useLocation();
  const [showDisabled, setShowDisabled] = useLocalStorage(`showDisabled-${pathname}`, false);

  const handleDisabledToggle = React.useCallback(() => {
    setShowDisabled((value) => !value);
  }, [setShowDisabled]);

  //---------------------------------------------------------------------------
  // Navbar button
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);
  React.useEffect(() => {
    setPageButtons(
      <>
        <ListItem sx={{ mb: 1 }} data-cy="add-parts-sidebar">
          <PartsForm partList={partList} setTableReload={setTableLoading} />
        </ListItem>
        {canEdit && (
          <ListItem sx={{ mb: 1 }} data-cy="edit-parts-sidebar">
            <EditButton data-cy="edit-parts">Edit Parts</EditButton>
          </ListItem>
        )}
        <ListItem sx={{ mb: 1 }}>
          <ToggleButton
            data-cy="toggle-disabled"
            onClick={handleDisabledToggle}
            enabled={showDisabled}
            enabledIcon={<VisibilityIcon />}
            disabledIcon={<VisibilityOffIcon />}
            enabledMessage="Hide Disabled"
            disabledMessage="Show Disabled"
          />
        </ListItem>
      </>
    );
    return () => setPageButtons(null);
  }, [partList, setPageButtons, canEdit, showDisabled, handleDisabledToggle]);

  //---------------------------------------------------------------------------
  // Periodically refetch the data
  //---------------------------------------------------------------------------
  const getParts = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/parts',
        });
        setPartList(data);
        setError(null);
        setTableLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [error, setError, setPartList]);
  useInterval(getParts, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const { search, setSearch, setSearchHelper, setSearchFields } = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearchFields('');
      setSearchHelper('');
    };
  }, [setSearchFields, setSearchHelper]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const formattedSearch = React.useMemo(() => {
    return showDisabled ? search : `${search} is:enabled`;
  }, [search, showDisabled]);
  const filteredParts = useFilter(partList, formattedSearch, searchFields);
  const [sortedParts, handleSortSelection, sort] = useSort(filteredParts, sortOptions);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageParts = React.useMemo(
    () => sortedParts.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedParts]
  );
  React.useEffect(() => {
    setPage(0);
  }, [search]);

  if (tableLoading) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <PartsHeader sort={sort} setSort={handleSortSelection} />
      {pageParts.map((part) => (
        <PartsRow
          key={part.PartNumber}
          part={part}
          partList={partList}
          setTableReload={setTableLoading}
        />
      ))}
      <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedParts.length} />
    </>
  );
}

export default PartsPage;
