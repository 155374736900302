const unitOptions = [
  'mm',
  'C',
  'F',
  'psi',
  'inH2O',
  'seconds',
  'minutes',
  'RPM',
  'lbf',
  'in-lbf',
  'unitless',
];

export default unitOptions;
