import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';

function PartsHeader({
  // Props
  sort,
  setSort,
}) {
  //---------------------------------------------------------------------------
  // Update the `sort` object for use by sortManager
  //---------------------------------------------------------------------------
  const handleSortSelection = (field) => setSort(field);

  return (
    <Box sx={{ p: 1 }}>
      <Grid container columns={32} id="headersRow" alignItems="flex-end" data-cy="parts-header">
        <Grid item xs={8} sm={3}>
          <ColumnHeader
            id="PartNumber"
            display="Part Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={14} sm={6}>
          <ColumnHeader
            id="Description"
            display="Description"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={8} sm={5}>
          <ColumnHeader
            id="QualityControlPlanId"
            display="QCP"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader
            id="DeviceMasterRecord"
            display="DMR"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader
            id="GTIN"
            display="GTIN"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader
            id="Vendor"
            display="Vendor"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item sm={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader
            id="Notes"
            display="Notes"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PartsHeader;
