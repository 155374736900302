import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import ParametersInputRow from './ParametersInputRow.jsx';

function FormParametersInput({
  // Props
  control,
  name,
  defaultValue,
  clearErrors,
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const handleCreate = React.useCallback(() => {
    append({ order: fields?.length, name: '', value: '', units: 'unitless' });
  }, [append, fields?.length]);

  const handleDeleteRow = React.useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  const list = useWatch({ control, name: 'parameters', defaultValue });
  React.useEffect(() => {
    // If there are no more duplicates, we need to manually remove the error message
    // from the inputs that weren't updated
    if (
      list?.some((e, i, arr) => {
        const foundIndex = arr.findIndex((inner) => inner.name === e.name);
        return foundIndex === i;
      })
    ) {
      clearErrors(name);
    }
  }, [clearErrors, list, name]);

  return (
    <Box sx={{ pr: '1em' }}>
      <TableContainer component={Paper}>
        <Table sx={{ ...(fields?.length && { marginBottom: '1em' }) }} data-cy="parameters-table">
          <TableHead>
            <TableRow>
              <TableCell variant="head">Parameter</TableCell>
              <TableCell variant="head">Value</TableCell>
              <TableCell variant="head">Units</TableCell>
              <TableCell variant="head" align="right">
                <IconButton color="secondary" onClick={handleCreate} data-cy="parameter-row-add">
                  <AddCircleIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields?.map(({ id }, index) => {
              return (
                <ParametersInputRow
                  key={id}
                  list={list}
                  control={control}
                  index={index}
                  handleDeleteRow={handleDeleteRow}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!fields.length && (
        <center style={{ marginTop: '5px' }}>
          <Typography color="text.secondary" variant="h7">
            Click the <AddCircleIcon fontSize="small" /> button to add a parameter
          </Typography>
        </center>
      )}
    </Box>
  );
}

export default FormParametersInput;
