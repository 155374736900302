import React from 'react';
import { useLocation } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';

import { useFilter, useInterval, useSort } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import Pagination from '../../components/common/Pagination.jsx';
import ControlledProcessForm from '../../components/ControlledProcessForm.jsx';
import EditButton from '../../components/EditButton.jsx';
import MeasurementsForm from '../../components/MeasurementsForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import ProcessHeader from './ProcessHeader.jsx';
import ProcessRow from './ProcessRow.jsx';

const DATA_REFRESH_INTERVAL_MS = 30_000;
const searchFields = {
  tool: 'ToolPartNumber',
  description: 'Description',
  type: 'TestType',
  min: 'TestMinimum',
  max: 'TestMaximum',
  samples: 'RequiredSampleCount',
};
const searchHelper = [
  { label: 'Has the words', keyword: '+', variant: 'global' },
  { label: "Doesn't have", keyword: '-', variant: 'global' },
  { label: 'Description', keyword: 'description', variant: 'negatable' },
  { label: 'Tool Part Number', keyword: 'tool', variant: 'negatable' },
  { label: 'Test Type', keyword: 'type', variant: 'negatable' },
  { label: 'Test Min', keyword: 'min', variant: 'negatable' },
  { label: 'Test Max', keyword: 'max', variant: 'negatable' },
  { label: 'Sample Count', keyword: 'samples', variant: 'negatable' },
];
const sortOptions = {
  defaultSort: {
    field: 'ToolPartNumber',
    reverse: false,
  },
};
const pageSize = 50;

function ControlledProcessesPage() {
  const roles = useAuth0Roles();
  const canEdit = React.useMemo(() => roles.includes('Progress-Tracker Admin'), [roles]);
  const [processes, setProcessesList] = React.useState([]);
  const [partList, setPartList] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);

  //---------------------------------------------------------------------------
  // Navbar page buttons
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);
  React.useEffect(() => {
    setPageButtons(
      canEdit && (
        <>
          <ListItem sx={{ mb: 1 }}>
            <ControlledProcessForm
              data-cy="add-process"
              setTableReload={setTableLoading}
              partList={partList}
              disabled={tableLoading}
            />
          </ListItem>
          <ListItem sx={{ mb: 1 }}>
            <EditButton data-cy="edit-processes">Edit Process</EditButton>
          </ListItem>
          <ListItem sx={{ mb: 1 }}>
            <MeasurementsForm
              setTableReload={setTableLoading}
              partList={partList}
              processes={processes}
              isFullWidth
              data-cy="record-measurement-button-sidebar"
            />
          </ListItem>
        </>
      )
    );
    return () => setPageButtons(null);
  }, [setPageButtons, canEdit, partList, processes, tableLoading]);

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const fetchDataAndProcesses = React.useCallback(async () => {
    try {
      const [partResponse, processResponse] = await Promise.all([
        axios.get('/api/parts'),
        axios.get('/api/processes'),
      ]);

      setPartList(partResponse.data);
      setProcessesList(processResponse.data);
      setError(null);
      setTableLoading(false);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
      setTableLoading(false);
    }
  }, []);

  useInterval(fetchDataAndProcesses, DATA_REFRESH_INTERVAL_MS, tableLoading && !error);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  // Update the search helper system
  const { search, setSearch, setSearchHelper, setSearchFields } = React.useContext(SearchContext);
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    setSearchFields(searchFields);
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearchFields('');
      setSearchHelper('');
    };
  }, [setSearchFields, setSearchHelper]);

  // Refresh the page data if the user navigates to the same page again (e.g. via side-nav)
  const location = useLocation();
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setTableLoading(true);
    };
  }, [location, setSearch]);

  const filteredProcesses = useFilter(processes, search, searchFields);
  const [sortedProcesses, handleSortSelection, sort] = useSort(filteredProcesses, sortOptions);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageProcesses = React.useMemo(
    () => sortedProcesses.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedProcesses]
  );
  React.useEffect(() => {
    setPage(0);
  }, [search]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  if (tableLoading) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <ProcessHeader sort={sort} setSort={handleSortSelection} />
      {pageProcesses.map((process) => (
        <ProcessRow
          key={process.Id}
          process={process}
          setTableReload={setTableLoading}
          partList={partList}
          processes={pageProcesses}
        />
      ))}
      <Pagination
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        count={sortedProcesses.length}
      />
    </>
  );
}

export default ControlledProcessesPage;
